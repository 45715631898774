import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Slide from "@mui/material/Slide";
import { SlideProps } from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import Image from "next/image";
import { DateTime } from "luxon";
import { Controller } from "react-hook-form";
import { Background, Parallax } from "react-parallax";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { ephesis, handlee } from "@/utils/fonts";
import LoadingIcon from "@/components/LoadingIcon";
import useBreakpoints from "@/hooks/useBreakpoints";
import useConfirmation from "@/hooks/useConfirmation";
import classes from "../../styles/Confirmation.module.css";

type TransitionProps = Omit<SlideProps, "direction">;

const TransitionUp = (props: TransitionProps) => {
  return <Slide {...props} direction="up" />;
};

const Confirmation = () => {
  const {
    diet,
    error,
    errors,
    control,
    openSnack,
    guestName,
    familyCode,
    familyData,
    arrivalDate,
    savingGuest,
    openAddGuest,
    settingEmail,
    openSetEmail,
    loadingFamily,
    responseMessage,
    guestConfirmation,
    watch,
    setDiet,
    onSubmit,
    handleClose,
    handleSubmit,
    searchFamily,
    setGuestName,
    setFamilyCode,
    handleOpenAdd,
    setArrivalDate,
    handleCloseAdd,
    handleCloseError,
    handleCloseEmail,
    handleSubmitGuest,
    handleChangeConfirmation,
  } = useConfirmation();

  const { smartphone, mobile, tablet, laptop, desktop } = useBreakpoints();

  return (
    <Parallax strength={300}>
      <Background className={"parallaxImage"}>
        <Image
          alt="Confirmation parallax Image"
          src={
            smartphone || mobile || tablet
              ? "/tequis_mobile.webp"
              : "/tequis_desk.webp"
          }
          loading={"lazy"}
          fill
          quality={100}
          style={{
            objectFit: "cover",
          }}
        />
      </Background>

      <Backdrop open={loadingFamily} style={{ zIndex: 10 }}>
        <LoadingIcon />
      </Backdrop>

      <Box className={classes.container}>
        <Card className={classes.glass}>
          <form onSubmit={searchFamily} style={{ width: "100%" }}>
            <Typography
              fontSize={{
                xs: "6vw",
                tablet: "3.5vw",
                md: "3vw",
                lg: "2.5vw",
                xl: "2vw",
              }}
              className={`${classes.fieldLabel} ${ephesis.className}`}
              color={"var(--color-primary-4)"}
            >
              Ingresa tu Código
            </Typography>

            <TextField
              fullWidth
              label={null}
              variant="outlined"
              value={familyCode}
              type="search"
              autoComplete="off"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFamilyCode(event.target.value);
              }}
              InputProps={{
                sx: {
                  borderRadius: "15px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      id="search"
                      aria-label="search"
                      onClick={searchFamily}
                      edge="end"
                    >
                      <SearchOutlinedIcon
                        sx={{
                          color: "var(--color-primary-4)",
                          fontSize: {
                            xs: "4vw",
                            tablet: "3.5vw",
                            md: "3vw",
                            lg: "2vw",
                          },
                        }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                className: classes.fieldText,
                "aria-label": "search",
              }}
              sx={{
                "& > .MuiInputBase-root > fieldset": {
                  border: "2px solid var(--color-primary-3)",
                },
                "& > .MuiInputBase-root:hover > fieldset": {
                  border: "2px solid var(--color-primary-4)",
                },
              }}
            />
          </form>

          {familyData && (
            <Stack mt={"2vh"} height={"100%"} justifyContent={"space-between"}>
              <Stack>
                <Typography
                  className={handlee.className}
                  textAlign={"center"}
                  fontSize={{
                    xs: "4vw",
                    tablet: "3vw",
                    md: "2.5vw",
                    lg: "2vw",
                  }}
                  // fontWeight={"700 !important"}
                  color={"var(--color-primary-4)"}
                >
                  Ha confirmado{" "}
                  {familyData.confirmed !== null ? familyData.confirmed : 0} de{" "}
                  {familyData.invited} invitados
                </Typography>

                {familyData.email !== "" && (
                  <Typography
                    className={handlee.className}
                    textAlign={"center"}
                    fontSize={{
                      xs: "3.5vw",
                      tablet: "2.5vw",
                      md: "2vw",
                      lg: "1.5vw",
                    }}
                    // fontWeight={"700 !important"}
                    color={"var(--color-primary-4)"}
                  >
                    El código de acceso se enviará a {familyData.email}
                  </Typography>
                )}
              </Stack>

              {familyData.guests.map((guest) => {
                return (
                  <Typography
                    key={guest.name}
                    // textAlign={"center"}
                    className={handlee.className}
                    sx={{
                      color: "var(--color-primary-4)",
                      fontSize: {
                        xs: "3.5vw",
                        tablet: "2.5vw",
                        md: "2vw",
                        lg: "1.5vw",
                      },
                      // fontWeight: "700 !important",
                    }}
                  >
                    {guest.name}
                  </Typography>
                );
              })}

              <Button
                id={"addGuest"}
                aria-label={"addGuest"}
                variant={"contained"}
                className={`${classes.submitButton} ${handlee.className}`}
                disabled={familyData.confirmed === familyData.invited}
                onClick={handleOpenAdd}
              >
                Dinos con quien nos acompañas
              </Button>
            </Stack>
          )}
        </Card>

        <Dialog
          open={openAddGuest}
          onClose={handleCloseAdd}
          maxWidth={smartphone || mobile || tablet ? "lg" : "sm"}
          fullWidth
          PaperProps={{
            className: "glassPane",
            sx: {
              background: "rgba(255, 211, 124, 0.57) !important",
            },
          }}
        >
          <Backdrop open={savingGuest} style={{ zIndex: 10 }}>
            <LoadingIcon />
          </Backdrop>
          <DialogTitle
            className={ephesis.className}
            sx={{
              fontSize:
                smartphone || mobile
                  ? "6vw"
                  : tablet
                  ? "4vw"
                  : laptop
                  ? "3vw"
                  : desktop
                  ? "2.5vw"
                  : "1vw",
              color: "var(--color-primary-4)",
              fontWeight: "600 !important",
              letterSpacing: "2px",
            }}
          >
            Agregar Invitado
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Typography
                className={handlee.className}
                sx={{
                  fontSize:
                    smartphone || mobile
                      ? "4vw"
                      : tablet || laptop
                      ? "3vw"
                      : desktop
                      ? "1.75vw"
                      : "1vw",
                  color: "var(--color-primary-4)",
                  fontWeight: "500 !important",
                }}
              >
                Nombre
              </Typography>
              <TextField
                value={guestName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setGuestName(event.target.value);
                }}
                InputProps={{
                  className: handlee.className,
                  sx: {
                    borderRadius: "15px",
                  },
                }}
                inputProps={{
                  className: classes.fieldNameText,
                }}
              />
              <Typography
                className={handlee.className}
                sx={{
                  fontSize:
                    smartphone || mobile
                      ? "4vw"
                      : tablet || laptop
                      ? "3vw"
                      : desktop
                      ? "1.75vw"
                      : "1vw",
                  color: "var(--color-primary-4)",
                  fontWeight: "500 !important",
                }}
              >
                Haznos saber si nos acompañaras
              </Typography>
              <RadioGroup
                value={guestConfirmation}
                onChange={handleChangeConfirmation}
              >
                <FormControlLabel
                  sx={{
                    border: "1px solid gray",
                    mx: 0,
                  }}
                  value="true"
                  control={<Radio />}
                  label={
                    <Typography
                      className={handlee.className}
                      sx={{ color: "var(--color-primary-4)" }}
                    >
                      Ahi estaré
                    </Typography>
                  }
                />

                <FormControlLabel
                  sx={{
                    border: "1px solid gray",
                    mx: 0,
                    mt: "1vh",
                  }}
                  value="false"
                  control={<Radio />}
                  label={
                    <Typography
                      className={handlee.className}
                      sx={{ color: "var(--color-primary-4)" }}
                    >
                      Lo siento, no podré asistir
                    </Typography>
                  }
                />
              </RadioGroup>
              <Typography
                className={handlee.className}
                sx={{
                  fontSize:
                    smartphone || mobile
                      ? "4vw"
                      : tablet || laptop
                      ? "3vw"
                      : desktop
                      ? "1.75vw"
                      : "1vw",
                  color: "var(--color-primary-4)",
                  fontWeight: "500 !important",
                }}
              >
                Tienes alguna restrición en tu dieta
              </Typography>
              <TextField
                value={diet}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setDiet(event.target.value);
                }}
                id="diet"
                multiline
                rows={3}
                variant="outlined"
                placeholder="Soy Vegano, alergías conocidas, etc."
                InputProps={{
                  className: handlee.className,
                  sx: {
                    borderRadius: "15px",
                  },
                }}
              />
              <Typography
                className={handlee.className}
                sx={{
                  fontSize:
                    smartphone || mobile
                      ? "4vw"
                      : tablet || laptop
                      ? "3vw"
                      : desktop
                      ? "1.75vw"
                      : "1vw",
                  color: "var(--color-primary-4)",
                  fontWeight: "500 !important",
                }}
              >
                ¿Llegarías el viernes 26 o el sábado 27?
              </Typography>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <DatePicker
                  minDate={DateTime.fromFormat("2024-01-26", "yyyy-LL-dd")}
                  maxDate={DateTime.fromFormat("2024-01-27", "yyyy-LL-dd")}
                  defaultValue={DateTime.fromFormat("2024-01-26", "yyyy-LL-dd")}
                  value={arrivalDate}
                  onChange={(newDate: any) =>
                    setArrivalDate(
                      DateTime.fromObject(
                        newDate ? newDate.c : { year: 2024, month: 1, day: 1 }
                      )
                    )
                  }
                  slotProps={{
                    textField: {
                      sx: {
                        "& > .MuiOutlinedInput-root > fieldset": {
                          borderRadius: "15px",
                        },
                      },
                    },
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack width={"100%"} spacing={1} marginX={"3vw"}>
              <Button
                id={"submitConfirmation"}
                aria-label={"submit"}
                className={`${classes.submitButton} ${handlee.className}`}
                variant="contained"
                fullWidth
                disabled={guestName === ""}
                onClick={handleSubmitGuest}
              >
                Guardar
              </Button>
              <Button
                id={"cancel"}
                aria-label={"cancel"}
                className={`${classes.secondaryButton} ${handlee.className}`}
                variant="contained"
                fullWidth
                onClick={handleCloseAdd}
              >
                Cancelar
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openSetEmail}
          onClose={handleCloseEmail}
          className={"glassPane"}
          maxWidth={smartphone || mobile || tablet ? "lg" : "sm"}
          fullWidth
        >
          <Backdrop open={settingEmail} style={{ zIndex: 10 }}>
            <LoadingIcon />
          </Backdrop>
          <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <Typography
                className={handlee.className}
                fontWeight={"600 !important"}
                color={"var(--color-primary-4)"}
              >
                Captura el correo al cual enviaremos el QR para acceder
              </Typography>
              <Controller
                control={control}
                name="email"
                rules={{
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "invalid email address",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    autoComplete="off"
                    error={errors?.email ? true : false}
                    InputProps={{
                      className: handlee.className,
                      sx: {
                        borderRadius: "15px",
                      },
                    }}
                    inputProps={{
                      style: {
                        textAlign: "center",
                      },
                    }}
                    fullWidth
                    placeholder={"example@example.com"}
                    type="email"
                    helperText={
                      errors?.email?.type === "required"
                        ? "Debe capturar email"
                        : errors?.email?.type === "pattern"
                        ? "Email invalido"
                        : ""
                    }
                  />
                )}
              />
            </DialogContent>

            <DialogActions>
              <Button
                type="submit"
                id={"submitEmail"}
                aria-label={"submitEmail"}
                className={`${classes.submitButton} ${handlee.className}`}
                variant="contained"
                fullWidth
                disabled={watch("email") === ""}
              >
                Guardar
              </Button>
            </DialogActions>
          </Box>
        </Dialog>

        <Snackbar
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          open={openSnack}
          autoHideDuration={6000}
          onClose={error ? handleCloseError : handleClose}
          TransitionComponent={TransitionUp}
        >
          <Alert
            onClose={error ? handleCloseError : handleClose}
            severity={error ? "error" : "success"}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {error
              ? "Ups! no encontramos tu código, intenta de nuevo"
              : responseMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Parallax>
  );
};

export default Confirmation;
