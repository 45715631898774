import { GET_FAMILY } from "@/graphql/queries/families"
import { Family } from "@/interface/family";
import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { ADD_GUEST } from "@/graphql/mutations/addGuest";
import { DateTime } from "luxon";
import { useForm } from "react-hook-form";
import { ADD_EMAIL_TO_FAMILY } from "@/graphql/mutations/addEmailToFamily";

const useConfirmation = () => {
  
  const [getFamily, { loading: loadingFamily }] = useLazyQuery(GET_FAMILY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only'
  });

  const [addGuest, { loading: savingGuest }] = useMutation(ADD_GUEST);

  const [setEmail, { loading: settingEmail }] = useMutation(ADD_EMAIL_TO_FAMILY);

  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('tablet'));

  const [familyData, setFamilyData ] = useState<Family | null>();
  const [familyCode, setFamilyCode] = useState('');
  const [openAddGuest, setOpenAddGuest] = useState(false);
  const [guestName, setGuestName] = useState('');
  const [guestConfirmation, setGuestConfirmation] = useState(true);
  const [openSnack, setOpenSnack] = useState(false);
  const [error, setError] = useState(false);
  const [responseMessage, setResponseMessage ] = useState('');
  const [diet, setDiet] = useState('');
  const [arrivalDate, setArrivalDate] = useState(DateTime.fromFormat('2024-01-26', 'yyyy-LL-dd'));
  const [openSetEmail, setOpenSetEmail] = useState(false);
  const { control, formState: { errors }, handleSubmit, setValue, watch} = useForm({
    defaultValues: {
      email: ''
    }
  });

  useEffect (() => {
    if (error)
      setOpenSnack(true);
  }, [error])

  const searchFamily = (e?: React.FormEvent) => {
    e?.preventDefault();
    getFamily({
      variables: {
        code: familyCode.toUpperCase()
      },
      onCompleted: (data) => {
        (data);
        setFamilyData(data.family);
        if (data.family.email === '')
          setOpenSetEmail(true);
      },
      onError: (error) => {
        (error);
        setError(true);
        // setOpenSnack(true)
      }
    })
  }

  const handleOpenAdd = () => {
    setOpenAddGuest(true);
  }

  const handleCloseAdd = () => {
    setOpenAddGuest(false);
    setGuestName('');
    setDiet('');
  }

  const handleCloseEmail = () => {
    setOpenSetEmail(false);
    setValue('email', '');
  }

  const handleClose = () => {
    setOpenSnack(false);
    setResponseMessage('');
  }

  const handleCloseError = () => {
    setOpenSnack(false);
    setFamilyData(null);
    setFamilyCode('');
    setError(false);
  }

  const handleChangeConfirmation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGuestConfirmation(Boolean(event.target.value));
  }

  const handleSubmitGuest = () => {
    if (guestName === '') return;
    if ( familyData ) {

      addGuest({
        variables: {
          familyId: Number(familyData.id),
          name: guestName.toUpperCase(),
          confirmed: guestConfirmation,
          diet: diet,
          arrival: arrivalDate.toFormat('yyyy-LL-dd')
        },
        onCompleted: (data) => {
          if (data.addGuest.id) {
            searchFamily();
            setOpenSnack(true);
            setResponseMessage('Invitado agregado correctamente');
            handleCloseAdd();
          }
        }
      });
    }
  }

  const onSubmit = (data: any) => {
    if ( familyData ) {
      setEmail({
        variables: {
          familyId: Number(familyData.id),
          email: data.email
        },
        onCompleted: (data) => {
          if (data.addEmailToFamily.id) {
            searchFamily();
            setOpenSnack(true);
            setResponseMessage('Email guardado correctamente');
            handleCloseEmail();
          }
        }
      })
    }
  }

  return {
    diet,
    error,
    tablet,
    errors,
    control,
    openSnack,
    guestName,
    familyCode,
    familyData,
    arrivalDate,
    savingGuest,
    openAddGuest,
    settingEmail,
    openSetEmail,
    loadingFamily,
    responseMessage,
    guestConfirmation,
    watch,
    setDiet,
    onSubmit,
    handleClose,
    handleSubmit,
    searchFamily,
    setGuestName,
    setFamilyCode,
    handleOpenAdd,
    setArrivalDate,
    handleCloseAdd,
    handleCloseError,
    handleCloseEmail,
    handleSubmitGuest,
    handleChangeConfirmation,
  }
}

export default useConfirmation