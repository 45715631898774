import { gql } from "@apollo/client";

export const ADD_GUEST = gql`
  mutation AddGuest($familyId: Int, $name: String, $confirmed: Boolean, $diet: String, $arrival: String ) {
    addGuest(familyId: $familyId, name: $name, confirmed: $confirmed, diet: $diet, arrival: $arrival) {
      arrival
      diet
      confirmed
      familyId
      id
      name
    }
  }
`