import { gql } from '@apollo/client';

export const GET_FAMILY = gql`
  query Family($code: String) {
    family(code: $code) {
      code
      confirmed
      guests {
        confirmed
        name
      }
      email
      qrcode
      id
      invited
      name
      mesa
    }
  }
`

export const GET_FAMILY_CODES = gql`
  query Families {
    families {
      code
    }
  }
`